@import './styles/base';
@import './styles/about';
@import './styles/header';
@import './styles/frases';
@import './styles/portfolio';
@import './styles/footer';
@import url('https://fonts.googleapis.com/css?family=Raleway:300&display=swap');

html{
  font-size: 62.5%;
}

body{
  background:$gradientblue;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div#root {
  width: 100%;
  text-align: center;
}