footer {
  text-align: center;

  .rrss {
    text-align: center;
    a {
      display: inline-block;
      padding: 2rem;
      margin: 0 0.2rem;
      display: inline-block;
      &:nth-of-type(1) {
        background: #d6249f;
        background: radial-gradient(
          circle at 30% 107%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
      }
      &:nth-of-type(2) {
        background-color: #0057ff;
        color: #fff;
      }

      &:nth-of-type(3) {
        background-color: #0077b5;
      }
      &:nth-of-type(4) {
        background-color: #1da1f2;
      }

      &:hover {
        opacity: 0.8;
        color: #fff;
      }
      svg {
        fill: #fff;
        width: 4rem;
        height: 4rem;
      }
    }
  }
  a {
    color: #feffdf;
    font-size: 2em;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease-out;
    padding: 2rem 0;
    display: inline-block;

    &:hover {
      color: $orange;
    }
    &:active {
      position: relative;
      top: 3px;
    }
  }
}
