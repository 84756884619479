section.hero {
  min-height: 50vh;
}
section h4 {
  font-size: 3.2em;
  color: #fff;
  font-weight: 100;
  text-shadow: 0 6px 10px rgba(0, 30, 30, 0.4);
  line-height: 1.4em;
  min-height: 3em;
  margin: 0;
}

.textos h4:after {
  display: inline-block;
  width: 0.06em;
  height: 0.8em;
  position: relative;
  top: 0.1em;
  content: "";
  background-color: #fff;
  animation: texto infinite 0.5s;
  margin-left: 0.2em;
}

@keyframes texto {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

article.textos {
  display: inline-block;
  padding: 3%;
  text-align: left;
  padding: 6% 4%;
  width: 80vw;
  bottom: 20%;
  p {
    color: #fff;
    text-align: left;
    opacity: 0;
    font-size: 2em;
    transition: 0.6s;
    &.on {
      opacity: 0.7;
    }
  }
}
