//colors
$orange:#ed7123;
$off-white:#eee;
$gradientblue:linear-gradient(180deg, rgba(34,38,75,1) 25%, rgba(20,75,87,1) 100%);
$fallback-gradientblue:rgb(34,38,75);

//medidas
$screen:100vh;
$s-size:1.2rem;
$m-size:1.6rem;
$l-size:2.4rem;
$xl-size:3.2rem;
