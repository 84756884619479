section.about {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  padding: 2% 3%;

  article {
    padding: 2% 3%;
    display: inline-block;
    background-color: #ffffff2a;

    h5 {
      color: #fff;
      font-size: $l-size;
      line-height: 1.2;
      margin: 0;
    }
    a,
    a.visited {
      color: $orange;
      text-decoration: none;
      transition: all 0.2s;
    }

    a:hover,
    a.visited:hover {
      color: #b8b9ff;
    }

    quote {
      color: #fff;
      font-size: 2em;
      line-height: 2.2em;
      background-color: #00000094;
      display: inline-block;
      padding: 2% 8%;
      margin-top: 2em;

      &:before {
        font-size: 4em;
        display: inline-block;
        content: "❝";
        font-family: "Noto Serif", serif;
        position: relative;
        vertical-align: middle;
        color: $orange;
      }

      &:after {
        font-size: 4em;
        display: inline-block;
        content: "❞";
        font-family: "Noto Serif", serif;
        position: absolute;
        color: $orange;
      }
    }
  }

  p {
    color: #fff;
    font-size: $m-size;
    line-height: 1.8em;
    text-align: left;
  }
  .circle {
    border-radius: 50%;
    width: 20rem;
    height: 20rem;
    overflow: hidden;
    margin: 2rem auto;
  }
  img {
    display: inline-block;
    width: 100%;
    height: auto;
  }
  strong,
  a {
    font-weight: 600;
  }
}
@media (min-width: 1800px) {
  section.about {
    padding: 5%;
    flex-flow: row-reverse;
  }
  section.about .circle {
    border-radius: 50%;
    width: 25rem;
    height: 20rem;
    overflow: hidden;
    margin: 0 2rem 0 0;
  }
}
