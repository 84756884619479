header {
  display: flex;
  flex-flow: column;
  align-content: center;
  justify-content: center;

  .logo {
    max-width: 15rem;
  }
  h1 a {
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    font-size: 4.4rem;
    color: $orange;
    font-weight: 300;
    text-decoration: none;
  }
  nav ul {
    display: flex;
    list-style: none;
    align-content: center;
    justify-content: center;
    a {
      color: $off-white;
      text-decoration: none;
      font-size: $m-size;
      padding: $s-size;
      &:hover {
        color: darken($off-white, 20%);
        transition: all 0.2s;
      }
    }
  }
  .active {
    font-weight: 600;
  }
}
