ul.portfolio {
  list-style: none;
  font-size: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 1 0 30%;
    min-width: 25rem;
  }
  a {
    height: 24rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 150%;
    background-position: center;
    transition: all 0.2s;
    font-size: $l-size;
    text-align: left;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    text-decoration: none;
    &:hover {
      background-size: 190%;
      opacity: 0.7;
    }
  }
  span {
    background-color: #00000080;
    color: #fff;
    padding: 0.2rem 1rem;

    &.titulo {
      display: inline-block;
      padding: 1.2rem 1rem 0.2rem 1rem;
      line-height: 1.2;
    }
    &.description {
      padding: $s-size;
      font-size: $m-size;
      display: block;
    }
  }

  li:nth-of-type(1) a {
    background-image: url("./img/xingyi.jpg");
  }
  li:nth-of-type(2) a {
    background-image: url("./img/peluqueria-jade-madrid.jpg");
  }
  li:nth-of-type(3) a {
    background-image: url("./img/carpa.jpg");
  }
  li:nth-of-type(4) a {
    background-image: url("./img/masexpo.jpg");
  }
  li:nth-of-type(5) a {
    background-image: url("./img/escuelas-infantiles.jpg");
  }
  li:nth-of-type(6) a {
    background-image: url("./img/madridmudanzas.jpg");
  }
  li:nth-of-type(7) a {
    background-image: url("./img/artdcar2.jpg");
  }
  li:nth-of-type(8) a {
    background-image: url("./img/lanka.jpg");
  }
  li:nth-of-type(9) a {
    background-image: url("./img/camacoca.jpg");
  }
  li:nth-of-type(10) a {
    background-image: url("./img/sava.jpg");
  }
  li:nth-of-type(11) a {
    background-image: url("./img//as.jpg");
  }
  li:nth-of-type(12) a {
    background-image: url("./img/chuches.jpg");
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .home li {
    display: inline;
  }
  .portfolio li {
    display: inline;
  }
}
